@import '../environments/scss/global';

$icons-url: url(#{$icons-path}/icons-v48.svg);
$default-logos-url: url(#{$icons-path}/default-logos.svg);

.icon {
  width: 28px;
  height: 28px;
  background-image: $icons-url !important;
  background-repeat: no-repeat !important;
  display: table-cell;
  vertical-align: middle;

  //Temp - new positions start

  &.main-grey {
    background-position: -74.35px -2.4px;
    width: 30px;
    height: 30px;
  }

  &.chains-dark {
    background-position: -78px -438px;
    width: 23px;
    height: 23px;
  }

  &.video-dark-button {
    background-position: -292px -436px;
    width: 27px;
    height: 27px;
  }

  &.picture {
    background-position: -4.5px -148.45px;
    width: 26px;
    height: 26px;
  }

  &.chains-circle-dark {
    background-position: -327.8px -471.9px;
    width: 27px;
    height: 27px;
  }

  &.plus {
    background-position: -81.5px -117.6px;
    width: 16px;
    height: 16px;
  }

  &.dots-vert {
    background-position: -194.5px -43.35px;
    width: 6px;
    height: 20px;
  }

  &.two-two-mode {
    background-position: -293px -291px;
    width: 25px;
    height: 27px;
  }

  &.plate-mmmm {
    background: -366px -652px;
    width: 292px;
    height: 243px;
  }

  &.list-mode {
    background-position: -293px -255px;
    width: 24px;
    height: 26px;
  }

  &.eye {
    background-position: -77px -189px;;
    width: 25px;
    height: 22px;
  }

  &.eye-hidden {
    background-position: -41px -189px;;
    width: 25px;
    height: 22px;
  }

  &.sold-out {
    background-position: -5px -187px;
    width: 25px;
    height: 22px;
  }

  &.selector-button {
    background-position: -254px -3px;
    width: 30px;
    height: 28px;
  }

  &.ellipsis {
    background-position: -188px -87px;
    width: 19px;
    height: 5px;
  }

  &.m-large-white {
    background-position: -257px -474px;
    width: 23px;
    height: 23px;
  }

  &.m-large-dark {
    background-position: -290px -472px;
    width: 32px;
    height: 23px;
  }

  &.main-circle-white {
    background-position: -111.9px -40.1px;
    width: 27px;
    height: 27px;
  }

  &.main-circle-dark {
    background-position: -111.9px -3.65px;
    width: 27px;
    height: 27px;
  }

  &.profile {
    background-position: -151.4px -41.2px;
    width: 20px;
    height: 24px;
  }

  &.profile-2 {
    background-position: -43.5px -221.1px;
    width: 20px;
    height: 24px;
  }

  &.heart-empty {
    background-position: -40.45px -41.5px;
    width: 26px;
    height: 24px;
  }

  &.subscribed-white {
    background-position: -225.25px -333.8px;
    width: 16px;
    height: 16px;
  }

  &.image-white {
    background-position: -112.45px -220.7px;
    width: 26px;
    height: 26px;
  }

  &.image {
    background-position: -218px -290px;
    width: 31px;
    height: 31px;
  }

  &.image-camera-white {
    background-position: -256px -292px;
    width: 27px;
    height: 27px;
  }

  &.flag {
    background-position: -294.5px -365.1px;
    width: 22px;
    height: 25px;
  }

  &.locations {
    background-position: -257.6px -365.9px;
    width: 24px;
    height: 24px;
  }

  &.subscribed-gold {
    background-position: -261px -333px;
    width: 16px;
    height: 16px;
  }

  &.sort {
    background-position: -151px -366px;
    width: 21px;
    height: 18px;
  }

  &.arrange {
    background-position: -151px -296px;
    width: 21px;
    height: 19px;
  }

  &.arrange-white {
    background-position: -151px -404px;
    width: 21px;
    height: 19px;
  }

  &.heart-white-empty {
    background-position: -77px -294px;
    width: 25px;
    height: 23px;
  }

  &.profile-mmmm {
    background-position: -184px -472px;
    width: 27px;
    height: 27px;
  }

  &.restaurant-mmmm {
    background-position: -183px -436px;
    width: 29px;
    height: 27px;
  }

  &.membership {
    background-position: -289.15px -145px;
    width: 50px;
    height: 32px;
  }

  &.share {
    background-position: -113px -293.5px;
    width: 25px;
    height: 23px;
  }

  &.location {
    background-position: -80px -294px;
    width: 19px;
    height: 24px;
  }

  &.call {
    background-position: -5px -294px;
    width: 25px;
    height: 24px;
  }

  &.message {
    background-position: -149px -294px;
    width: 24px;
    height: 24px;
  }

  &.dots-h {
    background-position: -185.45px -84.35px;
    width: 24px;
    height: 10px;
  }

  &.example-white {
    background-position: -17px -915px;
    width: 125px;
    height: 44px;
  }

  &.example-dark {
    background-position: -17px -972px;
    width: 125px;
    height: 44px;
  }

  &.example-colored {
    background-position: -17px -1030px;
    width: 125px;
    height: 44px;
  }

  &.play-dark {
    background-position: -219px -436px;
    width: 29px;
    height: 27px;
  }

  &.play-icon {
    background-position: -219px -472px;
    width: 28px;
    height: 27px;
  }

  &.dots-v-white {
    background-position: -194.5px -151.5px;
    width: 6px !important;
    height: 20px !important;
  }

  &.dots-v {
    background-position: -191.5px -38.35px;
    width: 8px !important;
    height: 30px !important;
  }

  &.chains-circle {
    background-position: -325px -4px;
    width: 30px;
    height: 27px;
  }

  &.mmmm-white {
    background-position: -74.5px -254.8px;
    width: 30px;
    height: 30px;
  }

  &.mmmm-white-circle {
    background-position: -75px -363px;
    width: 29px;
    height: 29px;
  }

  &.rest-selector {
    background-position: -39.5px -4px;
    width: 28px;
    height: 28px;
  }

  //Temp - new positions end
}

.default-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  &.default-logo-light {
    &:before {
      background: $default-logos-url no-repeat -402.4px -254.55px;
      width: 154px;
      height: 90px;
      content: '';
    }
  }

  &.default-logo-dark {
    &:before {
      background: $default-logos-url no-repeat -402.4px -354.1px;
      width: 154px;
      height: 90px;
      content: '';
    }
  }
}

.icon-v2-btn {
  display: inline-flex;
}

.icon-v2 {
  &:not(.picker-select) {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: .2s ease-in-out;

    &:before {
      background-image: $icons-url;
      background-repeat: no-repeat;
      display: table-cell;
      vertical-align: middle;
      content: '';
    }
  }

  &.picker-select {
    background-image: $icons-url;
    background-repeat: no-repeat;
    display: table-cell;
    vertical-align: middle;
  }

  &.mmmm-logo-black {
    width: 27px;
    height: 27px;

    &:before {
      background-position: -184px -436px;
      width: 27px;
      height: 27px;
    }
  }

  &.heart-empty-favorite {
    width: 292px;
    height: 243px;

    &:before {
      background-position: -367px -408px;
      width: 292px;
      height: 243px;
    }
  }

  &.heart-black {
    width: 26px;
    height: 24px;

    &:before {
      background-position: -5px -330px;
      width: 26px;
      height: 24px;
    }
  }

  &.goal-red {
    &:before,
    &.picker-select {
      background-position: -39.5px -219.55px;
      width: 28px;
      height: 28px;
    }
  }

  &.goal-white {
    &:before,
    &.picker-select {
      background-position: -39.5px -255.5px;
      width: 28px;
      height: 28px;
    }
  }

  &.goal-black {
    &:before,
    &.picker-select {
      background-position: -38.75px -323.05px;
      width: 32px;
      height: 32px;
    }
  }

  &.goal-white-reached {
    &:before {
      background-position: -38.75px -359.05px;
      width: 32px;
      height: 32px;
    }
  }

  &.toggle {
    width: 27px;
    height: 27px;

    &:before {
      background-position: -112px -256px;
      width: 27px;
      height: 27px;
    }
  }

  &.mmmm-logo-white {
    width: 27px;
    height: 27px;

    &:before {
      background-position: -148px -436px;
      width: 27px;
      height: 27px;
    }
  }

  &.mmmm-logo-dark {
    width: 27px;
    height: 27px;

    &:before {
      background-position: -148px -436px;
      width: 27px;
      height: 27px;
    }
  }

  &.phone-dark {
    width: 25px;
    height: 29px;

    &:before {
      background-position: -113px -74px;
      width: 25px;
      height: 27px;
    }
  }

  &.phone-white {
    width: 25px;
    height: 25px;

    &:before {
      background-position: -77px -77px;
      width: 25px;
      height: 25px;
    }
  }

  &.find-me {
    width: 25px;
    height: 25px;

    &:before {
      background-position: -329px -149px;
      width: 25px;
      height: 25px;
    }
  }

  &.add-to-home-screen {
    width: 23px;
    height: 30px;

    &:before {
      background-position: -6px -468px;
      width: 23px;
      height: 30px;
    }
  }

  &.enjoy-app {
    width: 29px;
    height: 25px;

    &:before {
      background-position: -327px -473px;
      width: 29px;
      height: 25px;
    }
  }

  &.download {
    width: 24px;
    height: 25px;

    &:before {
      background-position: -329px -437px;
      width: 24px;
      height: 25px;
    }
  }

  &.chains-circle-white {
    width: 27px;
    height: 27px;

    &:before {
      background-position: -292px -4px;
      width: 27px;
      height: 27px;
    }
  }

  &.dots-h {
    width: 24px;
    height: 24px;

    &:before {
      background-position: -185.45px -84.35px;
      width: 24px;
      height: 10px;
    }
  }

  &.share-dark {
    width: 32px;
    height: 32px;

    &:before {
      background-position: -113px -330px;
      width: 25px;
      height: 23px;
    }
  }

  &.location {
    width: 32px;
    height: 32px;

    &:before {
      background-position: -296px -149px;
      width: 19px;
      height: 25px;
    }
  }

  &.location-dark {
    width: 32px;
    height: 32px;

    &:before {
      background-position: -80px -329px;
      width: 20px;
      height: 25px;
    }
  }

  &.video-white-button {
    width: 27px;
    height: 27px;

    &:before {
      background-position: -256px -436px;
      width: 27px;
      height: 27px;
    }
  }

  &.video-dark-button {
    width: 27px;
    height: 27px;

    &:before {
      background-position: -292.3px -435.85px;
      width: 27px;
      height: 27px;
    }
  }

  &.circle-call {
    width: 32px;
    height: 32px;

    &:before {
      background-position: -220px -400px;
      width: 27px;
      height: 27px;
    }
  }

  &.circle-call-white {
    width: 32px;
    height: 32px;

    &:before {
      background-position: -256px -400px;
      width: 27px;
      height: 27px;
    }
  }

  &.circle-call {
    width: 32px;
    height: 32px;

    &:before {
      background-position: -220px -400px;
      width: 27px;
      height: 27px;
    }
  }

  &.call-dark {
    width: 32px;
    height: 32px;

    &:before {
      background-position: -114px -77px;
      width: 25px;
      height: 25px;
    }
  }

  &.dots-h-white {
    width: 24px;
    height: 24px;
    padding: 7px 0;

    &:before {
      background-position: -187.45px -120.35px;
      width: 24px;
      height: 10px;
    }
  }

  &.dots-v {
    width: 6px;
    height: 20px;

    &:before {
      background-position: -194.5px -43.35px;
      width: 6px;
      height: 20px;
    }
  }

  &.dots-v-white {
    width: 6px;
    height: 20px;

    &:before {
      background-position: -194.5px -151.5px;
      width: 6px;
      height: 20px;
    }
  }

  &.eye {
    width: 24px;
    height: 16px;

    &:before {
      background-position: -77.5px -189.7px;
      width: 24px;
      height: 16px;
    }
  }

  &.eye-hidden {
    width: 25px;
    height: 21px;

    &:before {
      background-position: -40.65px -187.95px;
      width: 25px;
      height: 21px;
    }
  }

  &.sold-out {
    width: 22px;
    height: 22px;

    &:before {
      background-position: -6.5px -186.5px;
      width: 22px;
      height: 22px;
    }
  }

  &.checkmark {
    width: 16px;
    height: 12px;

    &:before {
      background-position: -119.05px -192.5px;
      width: 16px;
      height: 12px;
    }
  }

  &.checkmark-red {
    width: 16px;
    height: 17px;

    &:before {
      background-position: -333px -45px;;
      width: 16px;
      height: 17px;
    }
  }

  &.checkmark-black {
    width: 17px;
    height: 16px;

    &:before {
      background-position: -262px -117px;
      width: 17px;
      height: 16px;
    }
  }

  &.checked {
    width: 15px;
    height: 12px;

    &:before {
      background-position: -120px -192px;
      width: 15px;
      height: 12px;
    }
  }

  &.checkmark-white {
    width: 17px;
    height: 17px;

    &:before {
      background-position: -226px -117px;
      width: 17px;
      height: 17px;
    }
  }

  &.checkmark-white2 {
    width: 13px;
    height: 13px;

    &:before {
      background-position: -227px -335px;
      width: 13px;
      height: 13px;
    }
  }

  &.arrange {
    width: 22px;
    height: 22px;

    &:before {
      background-position: -150.85px -84px;
      width: 22px;
      height: 10px;
    }
  }

  &.instagram {
    width: 26px;
    height: 26px;

    &:before {
      background-position: -148.4px -256.8px;
      width: 26px;
      height: 26px;
    }
  }

  &.instagram-white {
    width: 25px;
    height: 25px;

    &:before {
      background-position: -113px -221px;
      width: 25px;
      height: 25px;
    }
  }

  &.google {
    width: 24px;
    height: 24px;

    &:before {
      background-position: -293.35px -113.7px;
      width: 24px;
      height: 24px;
    }
  }

  &.google-white {
    width: 24px;
    height: 24px;

    &:before {
      background-position: -150.1px -222.2px;
      width: 22.65px;
      height: 23.15px;
    }
  }

  &.facebook {
    width: 24px;
    height: 24px;

    &:before {
      background-position: -258px -222px;
      width: 23px;
      height: 23px;
    }
  }

  &.facebook-white {
    width: 24px;
    height: 24px;

    &:before {
      background-position: -77.7px -222px;
      width: 23.55px;
      height: 23.55px;
    }
  }

  &.twitter {
    width: 23px;
    height: 21px;

    &:before {
      background-position: -330px -223px;
      width: 23px;
      height: 21px;
    }
  }

  &.twitter-white {
    width: 23px;
    height: 21px;

    &:before {
      background-position: -330px -259px;
      width: 23px;
      height: 21px;
    }
  }

  &.delete {
    width: 16px;
    height: 16px;

    &:before {
      background-position: -118.1px -154.7px;
      width: 14px;
      height: 14px;
      transform: scale(1.1);
    }
  }

  &.send {
    width: 30px;
    height: 26px;

    &:before {
      background-position: -3.05px -5.6px;
      width: 29.5px;
      height: 26px;
    }
  }

  &.image {
    width: 32px;
    height: 32px;

    &:before {
      background-position: -218px -290.35px;
      width: 31px;
      height: 31px;
    }
  }

  &.chain-white {
    width: 22px;
    height: 21px;

    &:before {
      background-position: -294px -223px;
      width: 22px;
      height: 21px;
    }
  }

  &.membership {
    width: 50px;
    height: 32px;

    &:before {
      background-position: -289.15px -145px;
      width: 50px;
      height: 32px;
    }
  }

  &.membership-gold {
    width: 284px;
    height: 180px;

    &:before {
      background-position: -370px -904px;
      width: 284px;
      height: 180px;
    }
  }

  &.star-white {
    width: 24px;
    height: 24px;

    &:before {
      background-position: -150.05px -114.95px;
      width: 23px;
      height: 21.3px;
    }
  }

  &.star-empty {
    &:before,
    &.picker-select {
      background-position: -39.55px -40.45px;
      width: 28px;
      height: 26px;
    }
  }

  &.star-empty-white {
    &:before,
    &.picker-select {
      background-position: -39.5px -292.5px;
      width: 28px;
      height: 26px;
    }
  }

  &.arrow-forward {
    width: 10px;
    height: 16px;

    &:before {
      background-position: -156.4px -333.8px;
      width: 10px;
      height: 16px;
    }
  }

  &.heart-empty-white {
    width: 26px;
    height: 24px;

    &:before {
      background-position: -41px -294px;
      width: 26px;
      height: 24px;
    }
  }

  &.arrow-circle-white {
    width: 27px;
    height: 27px;

    &:before {
      background-position: -328px -292px;
      width: 27px;
      height: 27px;
    }
  }

  &.arrow-forward-white {
    width: 11px;
    height: 19px;

    &:before {
      background-position: -156px -151px;
      width: 11px;
      height: 19px;
    }
  }

  &.arrow-forward-full-white {
    width: 27px;
    height: 27px;

    &:before {
      background-position: -4px -364px;
      width: 27px;
      height: 27px;
    }
  }

  &.arrow-dropdown {
    width: 11px;
    height: 6px;

    &:before {
      background-position: -229.15px -50.3px;
      width: 11px;
      height: 6px;
    }
  }

  &.filter {
    width: 23px;
    height: 24px;

    &:before {
      background-position: -114px -402px;;
      width: 23px;
      height: 24px;
    }
  }

  &.arrow-dropdown-white {
    width: 11px;
    height: 6px;

    &:before {
      background-position: -265.1px -50.3px;
      width: 11px;
      height: 6px;
    }
  }

  &.add-circle {
    width: 24px;
    height: 24px;

    &:before {
      background-position: -185.55px -185.75px;
      width: 24px;
      height: 24px;
    }
  }

  &.remove-circle {
    width: 24px;
    height: 24px;

    &:before {
      background-position: -149.55px -185.75px;
      width: 24px;
      height: 24px;
    }
  }

  &.information {
    width: 17px;
    height: 17px;

    &:before {
      background-position: -225.3px -9px;
      width: 17px;
      height: 17px;
    }
  }

  &.subscribed-gold {
    width: 18px;
    height: 18px;

    &:before {
      background-position: -260.6px -332.9px;
      width: 18px;
      height: 18px;
    }
  }

  &.mmmm-white {
    &:before {
      background-position: -74.5px -254.8px;
      width: 30px;
      height: 30px;
    }
  }

  &.mmmm-black-new {
    &:before {
      background-position: -75px -3px;
      width: 30px;
      height: 30px;
    }
  }

  &.mmmm-red {
    width: 42px;
    height: 42px;

    &:before {
      background-position: -248.55px -68.55px;
      width: 42px;
      height: 42px;
    }
  }

  &.mmmm-black {
    width: 25px;
    height: 25px;

    &:before {
      background-position: -5px -401px;
      width: 25px;
      height: 25px;
    }
  }

  &.mmmm-2x-red {
    width: 73px;
    height: 73px;

    &:before {
      background-position: -676.05px -320.35px;
      width: 73px;
      height: 73px;
    }
  }

  &.mmmm-2x-white, &.mmmm-2x-gray {
    width: 73px;
    height: 73px;

    &:before {
      background-position: -676.05px -235.8px;
      width: 73px;
      height: 73px;
    }
  }

  &.mmmm-2x-gray {
    &:before {
      opacity: .3;
    }
  }

  &.mmmm-2x-black {
    width: 73px;
    height: 73px;

    &:before {
      background-position: -676.2px -151.2px;
      width: 73px;
      height: 73px;
    }
  }

  &.hero-small {
    width: 34px;
    height: 24px;

    &:before {
      background-position: -216.55px -145.75px;
      width: 34px;
      height: 23px;
    }
  }

  &.hero {
    width: 300px;
    height: 200px;

    &:before {
      background-position: -362px -0px;
      width: 300px;
      height: 191px;
    }
  }

  &.hero-profile {
    width: 300px;
    height: 200px;

    &:before {
      background-position: -362px -200px;
      width: 300px;
      height: 200px;
    }
  }

  &.instruction {
    width: 28px;
    height: 28px;

    &:before {
      background-position: -77.55px -149px;
      width: 28px;
      height: 26.3px;
    }
  }

  &.explore {
    width: 28px;
    height: 28px;

    &:before {
      background-position: -3.55px -39.6px;
      width: 28px;
      height: 28px;
    }
  }

  &.explore-active {
    width: 28px;
    height: 28px;

    &:before {
      background-position: -3.95px -75.6px;
      width: 28px;
      height: 28px;
    }
  }

  &.explore-white {
    width: 28px;
    height: 28px;

    &:before {
      background-position: -183.6px -364px;
      width: 28px;
      height: 28px;
    }
  }

  &.explore-full {
    width: 27px;
    height: 27px;

    &:before {
      background-position: -184px -400px;
      width: 27px;
      height: 27px;
    }
  }

  &.profile-white {
    width: 19px;
    height: 24px;

    &:before {
      background-position: -152px -221px;
      width: 19px;
      height: 24px;
    }
  }

  &.profile-full {
    width: 27px;
    height: 27px;

    &:before {
      background-position: -220px -220px;
      width: 27px;
      height: 27px;
    }
  }

  &.profile-dark {
    width: 27px;
    height: 27px;

    &:before {
      background-position: -220px -256px;
      width: 27px;
      height: 27px;
    }
  }

  &.profile-dark {
    width: 19px;
    height: 24px;

    &:before {
      background-position: -152px -41px;
      width: 19px;
      height: 24px;
    }
  }

  &.home-white {
    width: 28px;
    height: 28px;

    &:before {
      background-position: -78px -403px;
      width: 25px;
      height: 25px;
    }
  }

  &.diamond {
    width: 28px;
    height: 28px;

    &:before {
      background-position: -4.5px -258.7px;
      width: 26px;
      height: 24px;
    }
  }

  &.direct {
    width: 32px;
    height: 28px;

    &:before {
      background-position: -1.85px -4.4px;
      width: 32px;
      height: 28px;
    }
  }

  &.diamond-blue {
    width: 26px;
    height: 24px;

    &:before {
      background-position: -40.6px -258.75px;
      width: 26px;
      height: 24px;
    }
  }

  &.chat {
    width: 28px;
    height: 28px;

    &:before {
      background-position: -219.55px -183.8px;
      width: 28px;
      height: 28px;
    }
  }

  &.chat-white {
    width: 28px;
    height: 28px;

    &:before {
      background-position: -255.55px -183.75px;
      width: 28px;
      height: 28px;
    }
  }

  &.chat-filled {
    width: 28px;
    height: 28px;

    &:before {
      background-position: -39.35px -4.2px;
      width: 28px;
      height: 28px;
    }
  }

  &.plus-dark {
    width: 16px;
    height: 15px;

    &:before {
      background-position: -333.6px -478.4px;
      width: 15px;
      height: 15px;
    }
  }

  &.plus-white {
    width: 16px;
    height: 16px;

    &:before {
      background-position: -46px -117.6px;
      width: 16px;
      height: 16px;
    }
  }

  &.plus {
    width: 16px;
    height: 16px;

    &:before {
      background-position: -81.5px -117.6px;
      width: 16px;
      height: 16px;
    }
  }

  &.arrow-back {
    width: 12px;
    height: 20px;

    &:before {
      background-position: -119.1px -114.55px;
      width: 12px;
      height: 20px;
    }
  }

  &.arrow-down {
    width: 9px;
    height: 5px;

    &:before {
      background-position: -230px -51px;
      width: 9px;
      height: 5px;
    }
  }

  &.arrow-back-white {
    width: 12px;
    height: 20px;

    &:before {
      background-position: -155.1px -150.55px;
      width: 12px;
      height: 20px;
    }
  }

  &.delete {
    width: 15px;
    height: 15px;

    &:before {
      background-position: -117.7px -154.15px;
      width: 15px;
      height: 15px;
    }
  }

  &.picture {
    width: 26px;
    height: 26px;

    &:before {
      background-position: -4.5px -148.45px;
      width: 26px;
      height: 26px;
    }
  }

  &.picture-white {
    width: 26px;
    height: 26px;

    &:before {
      background-position: -40px -149px;
      width: 26px;
      height: 26px;
    }
  }

  &.slide-up {
    width: 25px;
    height: 24px;

    &:before {
      background-position: -41px -474px;
      width: 25px;
      height: 24px;
    }
  }

  &.explore-white-post {
    width: 27px;
    height: 27px;

    &:before {
      background-position: -184px -364px;
      width: 27px;
      height: 27px;
    }
  }

  &.delimiter-gold {
    width: 27px;
    height: 27px;

    &:before {
      background-position: -76px -328px;
      width: 27px;
      height: 27px;
    }
  }

  &.clock {
    width: 27px;
    height: 27px;

    &:before {
      background-position: -328px -364px;
      width: 27px;
      height: 27px;
    }
  }

  &.clock-dark {
    width: 27px;
    height: 34px;

    &:before {
      background-position: -328px -324px;
      width: 27px;
      height: 31px;
    }
  }

  &.shop {
    width: 23px;
    height: 27px;

    &:before {
      background-position: -222px -184px;
      width: 23px;
      height: 27px;
    }
  }

  &.shop-white {
    width: 23px;
    height: 28px;

    &:before {
      background-position: -294px -185px;
      width: 23px;
      height: 28px;
    }
  }

  &.play {
    width: 27px;
    height: 32px;

    &:before {
      background-position: -220px -466px;
      width: 27px;
      height: 33px;
    }
  }

  &.stats {
    width: 23px;
    height: 19px;

    &:before {
      background-position: -330px -188px;
      width: 23px;
      height: 19px;
    }
  }

  &.rest-selector {
    width: 25px;
    height: 24px;

    &:before {
      background-position: -41px -6px;
      width: 25px;
      height: 24px;
    }
  }

  &.selector-button {
    width: 27px;
    height: 23px;

    &:before {
      background-position: -256px -258px;
      width: 27px;
      height: 23px;
    }
  }

  &.chains-dark {
    width: 23px;
    height: 23px;

    &:before {
      background-position: -78px -438px;
      width: 23px;
      height: 23px;
    }
  }

  &.chains-new {
    width: 23px;
    height: 23px;

    &:before {
      background-position: -42px -438px;
      width: 23px;
      height: 23px;
    }
  }

  &.chains {
    width: 32px;
    height: 32px;

    &:before {
      background-position: -217.65px -253.8px;
      width: 32px;
      height: 32px;
    }
  }

  &.heart-empty {
    width: 28px;
    height: 26px;

    &:before {
      background-position: -39.45px -40.5px;
      width: 28px;
      height: 26px;
    }
  }

  &.heart-red {
    &:before,
    &.picker-select {
      background-position: -40.5px -77.5px;
      width: 26px;
      height: 24px;
    }
  }

  &.menu {
    width: 24px;
    height: 24px;

    &:before {
      background-position: -294px -191.25px;
      width: 22px;
      height: 12px;
    }
  }

  &.menu-icon-white {
    width: 30px;
    height: 30px;

    &:before {
      background-position: -148px -400px;
      width: 27px;
      height: 27px;
    }
  }

  &.search-white {
    width: 24px;
    height: 24px;

    &:before {
      background-position: -78px -42px;
      width: 24px;
      height: 24px;
    }
  }

  &.hamburger-dark {
    width: 21px;
    height: 15px;

    &:before {
      background-position: -295px -118px;
      width: 21px;
      height: 15px;
    }
  }

  &.search-black-new {
    width: 24px;
    height: 24px;

    &:before {
      background-position: -6px -438px;
      width: 24px;
      height: 24px;
    }
  }

  &.find-me-black {
    width: 25px;
    height: 25px;

    &:before {
      background-position: -257px -401px;
      width: 25px;
      height: 25px;
    }
  }

  &.search-black {
    width: 25px;
    height: 25px;

    &:before {
      background-position: -330px -187px;
      width: 25px;
      height: 25px;
    }
  }

  &.location-mini-white {
    width: 13px;
    height: 16px;

    &:before {
      background-position: -191.1px -224.7px;
      width: 13px;
      height: 16px;
    }
  }

  &.flag {
    &:before {
      background-position: -294.5px -365.1px;
      width: 22px;
      height: 25px;
    }
  }

  &.locations {
    &:before {
      background-position: -257.6px -365.9px;
      width: 24px;
      height: 24px;
    }
  }

  &.hamburger {
    &:before {
      background-position: -329.3px -80.85px;
      width: 24px;
      height: 18px;
    }
  }

  &.hamburger-white {
    &:before {
      background-position: -293.4px -80.85px;
      width: 25px;
      height: 22px;
    }
  }

  &.asterisk {
    &:before {
      background-position: -228.3px -83.95px;
      width: 11px;
      height: 11px;
    }
  }

  &.share {
    &:before {
      background-position: -112.85px -294.05px;
      width: 26px;
      height: 23px;
    }
  }

  &.share-white {
    &:before {
      background-position: -113px -294px;
      width: 25px;
      height: 22px;
    }
  }

  &.mmmm-social {
    &:before {
      background-position: -3.5px -399.75px;
      width: 28px;
      height: 28px;
    }
  }

  &.tiktok {
    &:before {
      background-position: -41.65px -400.25px;
      width: 24px;
      height: 27px;
    }
  }

  &.scale {
    &:before {
      background-position: -182.6px -326.85px;
      width: 30px;
      height: 30px;
      transform: scale(.8);
    }
  }

  &.rotate {
    &:before {
      background-position: -325.2px -109.85px;
      width: 32px;
      height: 32px;
      transform: scale(.8);
    }
  }

  &.cup {
    &:before {
      background-position: -259.5px -187.4px;
      width: 20px;
      height: 20px;
    }
  }

  &.best-picture {
    &:before {
      background-position: -111.45px -474.6px;
      width: 28px;
      height: 22px;
      transform: scale(2);
    }
  }

  &.best-picture-gold {
    &:before {
      background-position: -112px -440px;
      width: 27px;
      height: 20px;
      transform: scale(2);
    }
  }

  &.heart-white {
    &:before {
      background-position: -3.55px -74.55px;
      width: 28px;
      height: 28px;
    }
  }

  &.navigation-left {
    &:before {
      background-position: -218.65px -470.5px;
      width: 30px;
      height: 30px;
      transform: rotate(180deg);
    }
  }

  &.navigation-right {
    &:before {
      background-position: -218.65px -470.5px;
      width: 30px;
      height: 30px;
    }
  }

  &.close-circle {
    &:before {
      background-position: -4px -112px;
      width: 27px;
      height: 27px;
    }
  }

  &.close {
    &:before {
      background-position: -117.15px -153.7px;
      width: 16px;
      height: 16px;
    }
  }

  &.close-light {
    &:before {
      background-position: -3.5px -111.45px;
      width: 28px;
      height: 28px;
    }
  }

  &.heart-fill {
    &:before {
      background-position: -3.6px -74.7px;
      width: 28px;
      height: 28px;
    }
  }
}
